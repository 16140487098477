import styled, { css } from 'styled-components'

import { DynamicBlock } from 'components/blocks/sections/DynamicBlock'
import { ColoredSectionStoryblok } from 'lib/storyblok/types'
import { getStoryblokImageAttributes } from 'lib/utils/content'

import { Theme } from '../../ui/deprecated/Theme'

type Props = {
  block: ColoredSectionStoryblok
}

export const ColoredSection: React.FC<Props> = ({ block }) => {
  const { image, theme, body } = block

  return (
    <Theme colorTheme={theme || 'light'}>
      <div data-theme={theme === 'dark-blue' ? 'dark' : 'light'}>
        <Wrapper image={getStoryblokImageAttributes(image).src}>
          {(body || []).map((currentBlock) => (
            <DynamicBlock key={currentBlock._uid} block={currentBlock} />
          ))}
        </Wrapper>
      </div>
    </Theme>
  )
}

const Wrapper = styled.div<{ image?: string | null }>`
  ${({ image }) =>
    image &&
    css`
      background-image: url('${image}');
      background-size: 100% auto;
      background-position: center center;
      background-repeat: no-repeat;
    `}
`
